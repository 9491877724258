<template>
    <div>
      
        <img src="@/assets/img/home/BabyShower/11.jpg" class="w-100 h-100 img-background" alt="Novios abrazados">
      
      <HomeCard v-for="(card, index) in cards" :datos="card" :key="index" :id="'home-card-'+index" class="mvh-lg-100" />
    </div>
  </template>
  <script>
    import HomeCard from '@/components/home/Card.vue'
    import Img1 from '@/assets/img/home/BabyShower/8.jpg'
    import Img1_2 from '@/assets/img/home/2-1.png'
    import Img1_3 from '@/assets/img/home/2-2.png'
  
    import Img2 from '@/assets/img/home/BabyShower/9.jpg'
    import Img2_2 from '@/assets/img/home/3-1.png'
  
    import Img3 from '@/assets/img/home/BabyShower/10.jpg'
    import Img3_2 from '@/assets/img/home/4-1.png'
    
    export default {
      name: 'Home',
      components: {
        HomeCard
      },
      data(){
        return {
          cards: [{
            title: 'Celebra con Nosotros tu Baby Shower',
            descripcion: "¡Únete a nosotros para celebrar la llegada del bebé más esperado del año! Estamos emocionados de invitarte a nuestro Baby Shower. Será un día lleno de alegría, amor y muchas sorpresas, mientras contamos los días para dar la bienvenida a nuestro pequeño tesoro. Prepárate para disfrutar de juegos divertidos, deliciosa comida y momentos inolvidables junto a familiares y amigos. Celebremos juntos este hermoso viaje hacia la maternidad/paternidad y hagamos de este Baby Shower un recuerdo especial para los futuros papás. No faltes a esta celebración única, donde cada sonrisa y cada abrazo serán un preludio del amor y la felicidad que rodearán al nuevo bebé. ¡Te esperamos para compartir esta dulce espera y darle la bienvenida a un mundo de cariño y ternura!",
            img1: Img1,
            img2: Img1_2,
            img3: Img1_3,
            bg: 'bg-primary text-white',
            align: 'end',
            url: 'como-funciona.index',
            urlname: 'Como funciona',
            urlClass: 'btn-light',
            hide: true
          },
          {
            title: 'Aprovecha cada Momento',
            descripcion: ' "El Baby Shower es mucho más que una fiesta; es una celebración de la vida, el amor y la esperanza. Es el momento en que amigos y familiares se unen para dar la bienvenida a un nuevo ser que llenará de luz y alegría la vida de sus padres. Este evento especial simboliza el comienzo de una nueva etapa llena de promesas y sueños por cumplir. El Baby Shower es una ocasión para rodear a los futuros padres con cariño y apoyo, para compartir consejos, risas y buenos deseos. Es el momento de preparar el camino para la llegada del bebé, asegurando que sienta el amor y la calidez desde el primer instante. Cada regalo, cada sonrisa y cada abrazo en esta celebración lleva consigo el mensaje de que este pequeño es esperado con los brazos abiertos y corazones llenos de amor.',
            img1: Img2,
            img2: Img2_2,
            bg: 'bg-secondary text-white',
            align: 'start',
            hide: true,
          },
          {
            title: 'Comparte',
            descripcion: 'Esta fiesta también es una oportunidad para honrar y celebrar a la futura mamá, reconociendo su valentía, su fuerza y la hermosa transformación que está viviendo. Es un tributo a la creación de una nueva familia y al vínculo eterno que comenzará a tejerse con la llegada del bebé. El Baby Shower, en esencia, es una fiesta de la vida misma, un recordatorio de que el amor se multiplica y que cada nuevo comienzo es un regalo precioso. Es una celebración de la comunidad, la familia y la maravillosa aventura de traer una nueva vida al mundo. Que esta celebración sea un preludio de toda la felicidad y bendiciones que están por venir. ¡Felices de compartir juntos este hermoso momento!"',
            img1: Img3,
            img2: Img3_2,
            bg: 'bg-light text-dark',
            align: 'end',
            hide: true
          }]
        }
      },
      mounted(){
        window.addEventListener('scroll', this.handleScroll);
      },
      unmounted(){
        window.removeEventListener('scroll', this.handleScroll)
      },
      methods: {
        handleScroll () {
          for (let index = 0; index < this.cards.length; index++) {
            var top = document.getElementById('home-card-'+index).offsetTop
            if(document.documentElement.scrollTop >= (top-20)){
              if(this.cards[index]['hide']){
                this.cards[index]['hide'] = false
              }
            }
          }
        }
      }
    }
  </script>
    